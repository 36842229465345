import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel, FormGroup, TextField, Button, CircularProgress } from "@mui/material";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { isEmail } from 'validator';
import "../App.scss";

const Invite = () => {

  let navigate                              = useNavigate();
  const { id }                              = useParams();
  const { windowWidth }                     = useWindowDimensions();

  const [isSaving, setIsSaving]             = useState(false);
  const [isFormValid, setIsFormValid]       = useState(false);

  // const [list, setList]                     = useState([]);
  const [invitees, setInvitees]             = useState([]);
  const [firstRowName, setFirstRowName]     = useState("");
  const [firstRowEmail, setFirstRowEmail]   = useState("");
  const [alwaysNotify, setAlwaysNotify]     = useState(false);

  useEffect(() => {
    let invitee = [{
      id: 1,
      name: "",
      email: "",
      showFullName: false,
      showEmail: false
    }]
    setInvitees(invitee);
    // document.body.style.backgroundColor = "#007670";
  }, []);

  // fullNameRegex allows letters, diacritics, spaces, single quotes, hyphens. needs one space so requires at least 2 words
  const fullNameRegex = /^([A-Za-zÀ-ÖØ-öø-ÿ'-]+ +)+[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;
  
  const submitToApi = (member) => {

    const payload = {
      members: member,
      notificationMessage: alwaysNotify,
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/share/' + id, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/invite-team-thanks/" + id, { replace: true });
      } else {
        setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsSaving(false);
      console.error(err);
    });

  }

  const handleCheckboxChange = (event) => {
    setAlwaysNotify(event.target.checked);
  };

  const handleSubmit = () => {
    setIsSaving(true);

    let formHasError          = false;
    invitees.forEach(invitee => {
      if(!fullNameRegex.test(invitee.name) || !invitee.name) {
        invitee.showFullName  = true;
        formHasError          = true; 
      }
      if(!isEmail(invitee.email)) {
        invitee.showEmail     = true;
        formHasError          = true;
      }
    });
    
    if(formHasError) {
      setTimeout(() => {setIsSaving(false);}, 500);
    } else {      
      submitToApi(invitees);
    }

  }

  const deletePerson = (p) => {    
    setInvitees(invitees.filter((person) => {
      return person.id !== p
    }));
  };

  const addPerson = () => {
    
    let id        = 2;
    if(invitees.length > 0) {
      id          = invitees[invitees.length - 1].id + 1;
    }
    
    let person = {
      id: id,
      name: "",
      email: ""
    }
    setInvitees([...invitees, person]);

  };

  const updateName = (e, id) => {
    let currentMember       = invitees.find(function (obj) { return obj.id == id; });
    currentMember.name      = e.target.value;
  }

  const updateEmail = (e, id) => {
    let currentMember       = invitees.find(function (obj) { return obj.id == id; });
    currentMember.email     = e.target.value;
  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "Send Invite";
  }

  const removeError = (id, field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#27282A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#151515";
    }

    let newinvitees = [];
    invitees.forEach(invitee => {
      if(field == "fullName" && invitee.id == id) {
        invitee.showFullName = false;
      }
      if(field == "email" && invitee.id == id) {
        invitee.showEmail = false;
      }
      newinvitees.push(invitee);
    });
    setInvitees(newinvitees);
  }


  return (
    <>
      <div className="container invite">
        <div className="details-container">
          <h1>Invite your team to take this same assessment</h1>
          <p>Invite your team and see everyone's responses on one dashboard.</p>
          {/* <p>Their results will populate your dashboard and as the Head of School, you'll see how the entire leadership team of the Board + Head of School aligns individually and collectively.</p> */}

          {
            invitees.map((data, index) => (
              <div className="form _form" key={data.id}>        
                <div className="invite-number">{index + 1}.</div>        
                <div className="form-detail">
                  <p className="input-title">Full Name</p>
                  <TextField hiddenLabel placeholder="E.g. Paul Bishop" onFocus={(e) => {removeError(data.id, "fullName", e)}} onChange={(e) => {updateName(e, data.id)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                  { 
                    data.showFullName ?
                      <div className="error-text">Please enter a full name.</div>
                    : null
                  }
                </div>
                <div className="form-detail">
                  <p className="input-title">Work Email</p>
                  <TextField hiddenLabel placeholder="E.g. paul@rock.church" onFocus={(e) => {removeError(data.id, "email", e)}} onChange={(e) => {updateEmail(e, data.id)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                  { 
                    data.showEmail ?
                      <div className="error-text">Please enter a valid email address.</div>
                    : null
                  }
                </div>
                { data.id != 1 ?
                  <div className="delete-btn">
                    <div onClick={() => deletePerson(data.id)}>
                      <DeleteIcon className="delete-icon" />
                    </div>
                  </div>
                  : null
                }
              </div>
            ))
          }
          
          {windowWidth > 830 && <div className="invite-another-person">
            <div className="invite-another">
              
              <div className="form_icons--icon">
                <Button onClick={addPerson} className="add-btn">
                  <AddIcon className="invite-another-icon" />ADD&nbsp;
                </Button>
              </div>
            </div>
          </div>}

          <div className="buttons-container">
            {windowWidth < 831 && <Button onClick={addPerson} className="add-btn">
              <AddIcon className="invite-another-icon" />ADD&nbsp;
            </Button>}
            <Button className="cta-btn" disabled={isSaving} onClick={() => handleSubmit()}>{showButton()}</Button>
          </div>

        </div>
      </div>
    </>
  );

};

export default Invite;
