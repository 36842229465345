import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import analyzeIcon from "../Assets/Images/analyze.png";
import alignIcon from "../Assets/Images/align.png";
import advanceIcon from "../Assets/Images/advance.png";
import Testimonial from "./Testimonial";
import useWindowDimensions from "../Hooks/useWindowDimensions";

const InviteInfo = (props) => {
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions()

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this same assessment</h3>
        {
          windowWidth >= 831 ?
            <p> Invite your team to take this same assessment with you and view your individual and group results to find out how well your digital tools serve your church’s mission. </p>
          :
            <p> Invite your team to take this same assessment with you and view your individual and group results to find out how well your digital tools serve your church’s mission. </p>
        }
        <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" />
            <br />
            <div className="height30"></div>
            <span>Analyze</span>
            <br />
            Understand different perspectives from your team
          </div>

          <div className="reason">
            <img src={alignIcon} alt="alignment icon" />
            <br />
            <div className="height30"></div>
            <span>Align</span>
            <br />
            Begin to align around a strategy for growth as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="direction icon" />
            <br />
            <div className="height30"></div>
            <span>Advance</span>
            <br />
            Chart the best course forward for your church
          </div>
        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}
      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default InviteInfo;
