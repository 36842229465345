import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

// import QuestionTemp from '../Components/QuestionsTemp';
import Question from '../Components/Questions';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import '../App.scss';
import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import useWindowDimensions from "../Hooks/useWindowDimensions";
import { style } from '@mui/system';


const AssessmentAdditionalV3 = () => {

  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id, pageId } = useParams();
  const assessProgressCtx = useContext(AssessmentProgressContext);
  const addCtx = useContext(AdditionalQuestionContext);


  console.log("\n\nAdditionalQuestionContext", addCtx, "\n\n");

  useEffect(() => {
    // document.body.style.backgroundColor = "#EEEEEE";

    getAdditional();
    localStorage.setItem("backFromAddQPage", true);
  }, []);


  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [prevValue, setPrevValue] = useState(0);
  const [textInput, setTextInput] = useState("");
  const [activeNextBtn, setActiveNextBtn] = useState(false);
  const [answerType, setAnswerType] = useState("");
  const [isUpdatingAns, setIsUpdatingAns] = useState(false);

  // const [nextDisabled, setNextDisabled] = useState(true);

  let backFromDetailsPage = localStorage.getItem("backFromDetailsPage") === "true" ? true : false;
  console.log("backFromDetailsPage", backFromDetailsPage);
  console.log("ACTIVE STEP IS", activeStep);
  useEffect(() => {
    if (backFromDetailsPage) {
      setActiveStep(questions?.length - 1);
    }
    else setActiveStep(0)
  }, [backFromDetailsPage, questions?.length])

  useEffect(() => {
    if (questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(questions?.[activeStep]?.answerTxt);
    }
    setAnswerType(questions?.[activeStep]?.answerType);
  }, [questions, activeStep]);

  useEffect(() => {
    if (addCtx.questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(addCtx.questions?.[activeStep]?.answerTxt);
    }
  }, [activeStep, addCtx]);

  useEffect(() => {
    setActiveNextBtn(false);
  }, [activeStep])

  function getAdditional() {

    if (addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId = process.env.REACT_APP_CLIENT_ID;

      // fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log("data", data);
          setQuestions(data?.data.questions);
          addCtx.addQuestions(data?.data.questions);
          setQuestionCount(data?.data.questions.length);
          // localStorage.removeItem("backFromDetailsPage");
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("Failed to fetch additional questions...", err));

    }

  }

  const submitAnswer = (answer) => {

    console.log("answer:", answer, "answerType:", answerType);
    const jsonBody = {
      questions: [
        {
          "id": questions?.[activeStep]?.id,
          "answer": answerType === "slider" ? answer : 0,
          "answerTxt": answerType === "textarea" ? textInput : "",
        }
      ],
    }
    // console.log(jsonBody);
    setIsUpdatingAns(true);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setIsUpdatingAns(false);
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        questions[activeStep].answer = answer;
        setTextInput("");
        if (activeStep < (questions?.length - 1)) {
          setActiveStep((prev) => prev + 1);
          // setPrevValue(questions?.[activeStep + 1].answer);
        } else {
          setActiveNextBtn(true);
          navigate("/details/" + id + "?" + searchParams, { replace: true });
        }
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      setIsUpdatingAns(false);
      console.error("Failed to send answer details...", err);
    });

  }

  const prevQuestion = () => {
    if (activeStep === 0) {
      // if(searchParams.get("leader") || searchParams.get("member")) {
      //   navigate("/pre-additional-info2/" + id + '?' + searchParams, { replace: true });
      // } else 
      // navigate("/assessment/" + id + "/review" + '?' + searchParams, { replace: true });
      navigate("/assessment/" + id + "/658?" + searchParams, { replace: true });
    } else {
      setActiveStep((prev) => prev - 1);
      setPrevValue(questions[activeStep - 1].answerTxt);
    }
  };

  // const nextQuestion = () => {
  //   if(activeStep < questions.length) {
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answerTxt);
  //     // setTimeout(() => {
  //       setActiveStep((prev) => prev + 1);
  //       setPrevValue(questions[activeStep + 1].answer);
  //       addCtx.updateAnswer(questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //       setTextInput("");
  //     // }, 50);
  //   } else {
  //     setTimeout(() => { completeAssessmentAdditional(); }, 500);
  //   }
  // };
  const nextQuestion = () => {
    // console.log("activeStep", activeStep); 
    // console.log("questions.length - 1", questions.length - 1);
    // if(activeStep < questions.length - 1) {
    //   addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    //   addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    //   // setTimeout(() => {
    //     // setTextInput("");
    //     // setActiveStep(activeStep + 1);
    //     // setPrevValue(questions[activeStep + 1].answer);
    //   // }, 20);
    // } else {
    //   // setTimeout(() => { completeAssessmentAdditional(); }, 500);
    // }
    // submitAnswer();

    // const detailsPageId = 644;
    console.log("activeStep", activeStep);
    console.log("questions.length - 1", questions.length - 1);
    console.log("questions?.[activeStep]", questions?.[activeStep]);
    console.log("textInput", textInput);
    const answerType = questions?.[activeStep]?.answerType;

    if (activeStep <= (questions?.length - 1)) {
      console.log("questions?.[activeStep]", questions?.[activeStep]);
      // questions are not mandatory so answerTxt?.length >= 0 or !answerTxt checks
      if (answerType === "textarea" && (questions?.[activeStep]?.answerTxt?.length >= 0 || !questions?.[activeStep]?.answerTxt)) {
        submitAnswer(textInput);
      }
      // the slider already has moveToNextQuestion function so this is for already answered questions 
      if ((answerType === "slider" || answerType === "radio") && (questions?.[activeStep]?.answer >= 0 || !questions?.[activeStep]?.answer)) {
        // setActiveStep((prev) => prev + 1); this is being done by submitAnswer function
        // if unanswered, then submit 0 since questions are not mandatory, else submit answer
        let answer = questions?.[activeStep]?.answer || 0;
        submitAnswer(answer);
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt) {
      if (questions[activeStep]?.answer > 0) {
        navigate("/details/" + id + "?" + searchParams, { replace: true });
      }
    }

  };

  console.log("questions", questions); console.log("addCtx.questions", addCtx.questions);
  console.log("questions[activeStep]", questions[activeStep]);

  const moveToNextQuestion = (answer) => {
    // questions[activeStep].answer = answer;
    console.log("answer", answer); console.log("textInput", textInput);
    addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    // if(activeStep + 1 < questions.length) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //   }, 500);
    // } else {      
    //   setTimeout(() => { completeAssessmentAdditional(); }, 500);
    // }

    setActiveNextBtn(true);
    submitAnswer(answer);

  }

  const updateTextQuestion = (answer) => {
    console.log("answer", answer);
    setTextInput(answer);
    // addCtx.updateTextAnswer(questions[activeStep + 1].id, answer);
    addCtx.updateTextAnswer(questions[activeStep].id, answer);
  }

  const hasAnswers = () => {
    questions.forEach(question => {
      if (questions?.[activeStep].answerTxt != "") {
        return 1;
      }
    })
    return 0;
  }


  const nextDisabled = () => {
    // if(questions[activeStep]?.mandatory === "no") {
    //   return { display: "flex" };
    // }
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if (questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "none" };
  }
  const checkDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return false;
    }
    if (questions?.[activeStep]?.answer > 0) {
      return false;
    };
    return true;
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }

  const nextQuestionClass = () => {
    // if(activeStep === 0) {
    //   return ""; } else 
    const mobileClass = windowWidth < 551 ? " mobile" : "";
    if (activeStep === questions?.length - 1) {
      return "all-set-btn next-btn";
    } else return "next-btn" + mobileClass;
  }

  const nextArrowFunc = () => {
    if (activeStep === 0) {
      return nextArrow;
    }
    return nextArrowWhite;
  }

  const getStepContent = (step) => {
    return (
      <Question question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} textAnswer={updateTextQuestion} />
    );
  };

  // const getStepContent = (step) => {
  //   if(step === 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }

  // };

  const showNextButton = () => {
    if (activeStep === (questions?.length - 1)) {
      return <>
        NEXT
      </>;
    } else if (activeStep === 1) {
      return <>
        {/* Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT

      </>;
    } else if (activeStep === 2) {
      return <>
        {/* Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT<img src={nextArrow} alt="Next Page Arrow" /> */}
        ALL DONE!

      </>;
    } else {
      return <>
        {/* &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT
      </>;
    }
  }

  console.log("\n\ntextInput", textInput);

  const showHeader = () => {
    switch (activeStep) {
      case 0: return "USAGE";
      case 1: return "USAGE";
      case 2: return "USAGE";
      default: return "USAGE";
    }
  }

  if (isLoading) {
    return <div className="container assessment-additional loading"><CircularProgress style={{ color: '#6EC5B8' }} size={60} /></div>
  }

  return (
    <div className="container assessment-additional" style={{ maxWidth: activeStep == 1 ? "1110px" : "850px" }}>
      <div>
        <Stepper activeStep={activeStep}></Stepper>
      </div>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer" style={{ height: windowWidth > 650 ? "50px" : "20px" }}></div>

              <h1>{showHeader()}</h1>
              {/* <h1>YOUR VALUABLE OPINION</h1> */}

              {/* <div className="spacer"></div> */}
              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              {/* <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  {showNextButton()}
                </Button>
              </div> */}

            </div>
          </div>
        </div>
      </div>

      {/* <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevQuestion()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
       {activeStep !== 0 && <Button style={activeStep == 2 ? {width: "250px"} : {width: "90px"}} className={activeNextBtn ? "cta-active" : "cta-disabled"} 
          disabled={textInput?.length > 0 || addCtx.questions?.[activeStep]?.answerTxt?.length > 0 ? false : true}
          // style={nextDisabled()} 
          onClick={() => nextQuestion()}
          //  disabled={textInput?.length || hasAnswers() > 0 ? false : true}
        >
          // NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" />
          {activeStep == 2 ? "ALL DONE!" : showNextButton()}
        </Button> }
      </div> */}

      <div className="step-buttons">
        {windowWidth > 550 && <Button onClick={prevQuestion}>
          <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
        </Button>}
        {windowWidth <= 550 && <Button onClick={prevQuestion}>
          <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
        </Button>}
        {windowWidth > 550 && <Button className={nextQuestionClass()} onClick={nextQuestion} style={{ display: "inline-flex" }}
        // disabled={checkDisabled()}
        // style={nextDisabled()}
        >
          {/* <span style={{ borderBottom: "1px solid #ABAD23" }}> */}
          {activeStep === questions?.length - 1 ? "ALL DONE!" : isUpdatingAns ? <CircularProgress style={{ color: '#FFF' }} size={24} /> : "NEXT"}
          {/* <>NEXT <img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" /></> */}
        </Button>}
        {windowWidth <= 550 && <Button className={nextQuestionClass()} onClick={nextQuestion} style={{ display: "inline-flex" }}
        // disabled={checkDisabled()}
        // style={nextDisabled()}
        >
          {activeStep === questions?.length - 1 ? "ALL DONE!" : <img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" />
          }
        </Button>}
      </div>

    </div>
  );
}

export default AssessmentAdditionalV3;
