import React from 'react';
import { Link } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import logoLeft from '../../Assets/Images/logo-left.png';
import logoLeftMobile from '../../Assets/Images/left-logo-mobile.svg';
// import logoLeftMobile from '../../Assets/Images/left-logo-mobile.jpeg';
// import bleatLogo from '../../Assets/Images/logo-bleat-desktop.png';
// import bleatLogo from '../../Assets/Images/assessment-logo-right.svg';
import bleatLogo from '../../Assets/Images/bleat-logo.png';
import bleatLogoMobile from '../../Assets/Images/bleat-logo-mobile.png';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header>
        <div>
        <div className="header-wrapper">
        
          {/* {windowWidth > 830 ? <Link to="/start" className="logo-left"><img src={logoLeft} alt="Logo" /></Link> : <Link to="/start" className="logo-left"><img src={logoLeftMobile} alt="Logo" /></Link>} */}
          {windowWidth > 830 ? <div className="logo-left"><img src={logoLeft} alt="Logo" /></div> : <div className="logo-left"><img src={logoLeftMobile} alt="Logo" /></div>}

          
          <div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">Digital Engagement Strategy</p>
            <p className="logo-subtext">ASSESSMENT</p>
          </div>

            {/* {windowWidth < 830 && <div className="left-logo" style={{ }}><Link to ="/start"><img src={logoLeftMobile} alt="church logo" width={192} height={42}/></Link></div>} */}

            {/* <div className="assessment-title desktop">
              <p className="logo-text">AI Pulse Check</p>
            </div> */}

          {/* {windowWidth > 830 ? <Link to="/start" className="logo-right" style={{ }}><img src={bleatLogo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/" className="logo-right"></Link>} */}
          {windowWidth > 830 ? <div className="logo-right" style={{ }}><img src={bleatLogo} width="auto" height={67.5} alt="Bleat" /></div> : <div className="logo-right"></div>}

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>
        </div>

      </header>
    </>
  )
}

export default Header;
