import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/jason.png';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../Assets/Images/leftQuote.png';
import bottomQ from '../Assets/Images/rightQuote.png';


const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="open quote" /></div>
            <p>
            Working with Apollos really feels like a partnership. I feel like the team is truly in for our win, wants me to win, wants CedarCreek to win; most importantly it wants to help CedarCreek reach and connect with more people! I am thankful for that.
            </p>
            {/* <p></p> */}
            
            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} alt="close quote" style={{transform:'rotate(180deg)'}} /></div></div>
            
          </div>
        <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={101} height={101} />
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          Jason Jones<p className="designation-inner">Executive Project Manager, CedarCreek Church</p> 
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
